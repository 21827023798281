<template>
  <div class="jh-dialog-wrap">
      <div class="jh-dialog-header">
          <h1>{{ param.headerTitle }}</h1>
          <div class="is-right">
              <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
          </div>
      </div>
      <div class="jh-dialog-body">
          <v-form ref="form" v-model="valid" lazy-validation>
              <table class="jh-tbl-detail">
                  <colgroup>
                      <col width="100px">
                      <col>
                      <col width="100px">
                      <col>
                  </colgroup>
                  <tbody>
                      <tr>
                          <th><label class="is-required">공통코드타입</label></th>
                          <td><v-text-field class="jh-form is-md" name="GROUP_CD" v-model="paramData.GROUP_CD" :disabled="is.groupCd" maxlength="60" :rules="validateRules.groupCd" required></v-text-field></td>
                          <th><label class="is-required">코드</label></th>
                          <td><v-text-field class="jh-form is-md" name="CD" v-model="paramData.CD" :disabled="is.cd" maxlength="120" :rules="validateRules.cd" required></v-text-field></td>
                      </tr>
                      <tr>
                          <th><label class="is-required">상세코드명</label></th>
                          <td colspan="3"><v-text-field class="jh-form is-md" name="CD_NM" v-model="paramData.CD_NM" maxlength="300" :rules="validateRules.cdNm" required></v-text-field></td>
                      </tr>
                      <tr>
                          <!-- <th><label class="is-required">코드구분</label></th>
                          <td><v-text-field class="jh-form is-md" name="CD_TYPE" v-model="paramData.CD_TYPE" :disabled="is.CD_TYPE" maxlength="20" :rules="validateRules.cdType" required></v-text-field></td> -->
                          <th><label class="is-required">정렬번호</label></th>
                          <td><v-text-field class="jh-form is-md" type="number" name="SORT_ORD" id="sortOrd" min="0" max="9999" v-model.number.lazy="paramData.SORT_ORD" :rules="validateRules.sortOrd" required></v-text-field></td>
                          <th><label class="is-required">사용여부</label></th>
                          <td>
                              <v-select
                                  class="jh-form is-md"
                                  :items="useYnDropItems"
                                  name="USE_YN"
                                  item-text="name"
                                  item-value="value"
                                  placeholder="선택하세요"
                                  v-model="paramData.USE_YN"
                                  :rules="validateRules.useYn"
                                  required
                                  @change="changeUseYn"
                              ></v-select>
                          </td>
                      </tr>
                      <!-- <tr>
                          <th><label class="is-required">사용시작일</label></th>
                          <td>
                              <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              min-width="auto"
                              offset-y
                              >
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                          v-model="from"
                                          v-bind="attrs"
                                          v-on="on"
                                          class="jh-form is-md"
                                          append-icon="jh-icon-calendar is-blue"
                                          @keyup.enter="menu2 = false"
                                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"
                                          :disabled="is.cdUseFrDt"
                                          :rules="validateRules.cdUseFrDt"
                                          required
                                          maxlength="10"
                                          @input="fromOnInput"
                                      ></v-text-field>
                                  </template>
                                  <v-date-picker
                                      class="jh-calendar"
                                      v-model="date"
                                      @input="menu2 = false"
                                      :first-day-of-week="1"
                                      no-title
                                      locale="ko-KR"
                                      :day-format="mixin_getDate"
                                      :max="date2"
                                      @change="startDate"
                                  ></v-date-picker>
                              </v-menu>
                          </td>
                          <th><label class="is-required">사용종료일</label></th>
                          <td>
                              <v-menu
                              v-model="menu1"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              min-width="auto"
                              offset-y
                              >
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                          v-model="to"
                                          v-bind="attrs"
                                          v-on="on"
                                          class="jh-form is-md"
                                          append-icon="jh-icon-calendar is-blue"
                                          @keyup.enter="menu1 = false"
                                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"
                                          :disabled="is.cdUseToDt"
                                          :rules="validateRules.cdUseToDt"
                                          required
                                          maxlength="10"
                                          @input="toOnInput"
                                      ></v-text-field>
                                  </template>
                                  <v-date-picker
                                      class="jh-calendar"
                                      v-model="date2"
                                      @input="menu1 = false"
                                      :first-day-of-week="1"
                                      no-title
                                      locale="ko-KR"
                                      :day-format="mixin_getDate"
                                      :min="date"
                                      @change="endDate"
                                  ></v-date-picker>
                              </v-menu>
                          </td>
                      </tr> -->
                      <tr>
                          <th><label>기타코드1</label></th>
                          <td><v-text-field class="jh-form is-md" name="ETC_INFO01" v-model="paramData.ETC_INFO01" :disabled="is.etcInfo01"></v-text-field></td>
                          <th><label>기타코드2</label></th>
                          <td><v-text-field class="jh-form is-md" name="ETC_INFO02" v-model="paramData.ETC_INFO02" :disabled="is.etcInfo02"></v-text-field></td>
                      </tr>
                      <tr>
                          <th><label>기타코드3</label></th>
                          <td><v-text-field class="jh-form is-md" name="ETC_INFO03" v-model="paramData.ETC_INFO03" :disabled="is.etcInfo03"></v-text-field></td>
                          <th><label></label></th>
                          <td></td>
                      </tr>
                      <tr>
                          <th><label>기타코드4</label></th>
                          <td colspan="3"><v-textarea class="jh-form" noResize style="height:90px" name="ETC_INFO04" v-model="paramData.ETC_INFO04"></v-textarea></td>
                      </tr>
                      <tr>                          
                        <th><label>상세내용</label></th>
                        <td colspan="3"><v-textarea class="jh-form" placeholder="상세내용을 입력하세요." style="height:120px" v-model="paramData.CD_XPLN"></v-textarea></td>
                      </tr>
                  </tbody>
              </table>
          </v-form>
      </div>
      <div class="jh-dialog-footer" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')">
          <v-btn class="jh-btn id-md is-main" @click="submit">{{ param.type == 'R' ? '수정' : '저장'}}</v-btn>
      </div>
  </div>
</template>

<script>
import {mixin} from "../../mixin/mixin";

export default {
  name: "MENU_E040103P02", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
  },
  mixins:[mixin],
  data(){
    return {


      menu1: false,
      menu2: false,
      from: "",
      to: "",
      date: {},
      date2: {},
      valid: true,

      validateRules:{
        groupCd: [
          v => !!v || '공통코드타입은 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        cd: [
          v => !!v || '코드는 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        cdNm: [
          v => !!v || '공통코드명은 필수입니다.',
        ],
        cdUseFrDt: [
          v => !!v || '사용시작일은 필수입니다.',
          v => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || '날짜는 YYYY-MM-DD 형식으로 작성해 주세요.',
        ],
        cdUseToDt: [
          v => !!v || '사용종료일은 필수입니다.',
          v => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || '날짜는 YYYY-MM-DD 형식으로 작성해 주세요.',
        ],
        cdType: [
          v => !!v || '코드구분은 필수입니다.',
        ],
        sortOrd: [
          //v => !!v || '정렬번호는 필수입니다.',
          v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
          v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
        ],
      },
      footerHideTitle: "닫기",
      useYnDropItems: [
        { name: "사용", value:"Y" },
        { name: "미사용", value:"N" },
      ],
      is: {
        groupCd: false,
        cd: true,
        cdNm: true,
        cdUseFrDt: false,
        cdUseToDt: false,
        cdType: false,
        etcInfo01: false,
        etcInfo02: false,
        etcInfo03: false,
        cdXpln: false
      },
    }
  },
  methods:{
    init(){
      let type = this.param.type;

      if(type == "R"){
        this.date= this.from = this.getDateFormat(this.paramData.CD_USE_FR_DT);
        this.date2= this.to = this.getDateFormat(this.paramData.CD_USE_TO_DT);
        this.is = {
          groupCd: true,
          cd: true,
          cdNm: true,
          cdUseFrDt: false,
          cdUseToDt: false,
          cdType: false,
          etcInfo01: false,
          etcInfo02: false,
          etcInfo03: false,
          cdXpln: false
        };
      }else{
        this.is = {
          groupCd: true,
          cd: false,
          cdNm: false,
          cdUseFrDt: false,
          cdUseToDt: false,
          cdType: false,
          etcInfo01: false,
          etcInfo02: false,
          etcInfo03: false,
          cdXpln: false
        };
        this.paramData.USE_YN = "Y";
        this.paramData.CD = "";
        this.paramData.CD_NM = "";
        this.date= this.from = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10);
        this.date2= this.to = new Date("2999-12-31").toISOString().substr(0, 10);
        this.paramData.CD_USE_FR_DT = this.date;
        this.paramData.CD_USE_TO_DT = this.date2;
        this.paramData.SORT_ORD = 0;
        this.paramData.ETC_INFO01 = "";
        this.paramData.ETC_INFO02 = "";
        this.paramData.ETC_INFO03 = "";
        this.paramData.ETC_INFO04 = "";
        this.paramData.CD_XPLN = "";
      }
    },
    getDateFormat(value){
      let resultDate = value.substr(0,4) + "-" + value.substr(4,2) + "-" + value.substr(6,2);
      return resultDate;
    },
    removeChar(event){
      let num = Number(event.target.value);
      if(isNaN(num)){
        this.paramData.SORT_ORD = 0;
      }else{
        this.paramData.SORT_ORD = num;
      }
    },
    validate () {
      return this.$refs.form.validate();
    },
    reset () {
      this.$refs.form.reset();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    async submit(){
      let type = this.param.type;

      if(!this.validate()){
        return;
      }

      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData:{}
      };


      this.paramData.CD_USE_FR_DT = this.date.replaceAll("-","");
      this.paramData.CD_USE_TO_DT = this.date2.replaceAll("-","");

      // 신규
      if(type === "C"){
        requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/regist";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = false;

        requestData.sendData= {};

        for(let prop in this.paramData){
          requestData.sendData[prop] = this.paramData[prop];
        }
        requestData.sendData["DATA_FLAG"]= "I";
        requestData.sendData["STR_DIV"]= "TYPE";
        requestData.sendData["PROC_ID"]= this.$store.getters['userStore/GE_USER_ROLE'].userId;
        requestData.sendData["REGR_DEPT_CD"]= "x";
        requestData.sendData["REGR_ID"]= this.$store.getters['userStore/GE_USER_ROLE'].userId;
        requestData.sendData["CD_PRE_TYPE"]= "TWB";
      }else{ // 상세 업데이트
        requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/modify";
        requestData.headers["METHOD"] = "modify";
        requestData.headers["ASYNC"] = false;
        requestData.sendData= {};
        for(let prop in this.paramData){
          requestData.sendData[prop] = this.paramData[prop];
        }
        requestData.sendData["DATA_FLAG"]= "U";
        requestData.sendData["STR_DIV"]= "TYPE";
        requestData.sendData["PROC_ID"]= this.$store.getters['userStore/GE_USER_ROLE'].userId;
        requestData.sendData["AMDR_DEPT_CD"]= "x";
        requestData.sendData["AMDR_ID"]= this.$store.getters['userStore/GE_USER_ROLE'].userId;
      }
      const response = await this.common_postCall(requestData);
      this.submitCallBack(response);
    },
    submitCallBack(response){
      if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error');
        return false;
      }
      this.common_alert(`정상처리 되었습니다.`);
      this.$emit('hideAndGetDetailList');
      return;
    },
    fromOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.date2)) {
          this.$nextTick(() => {
            this.from = this.date2;
          });
        }

        this.$nextTick(() => {
          this.date = this.from;
          // datepick 다시 랜더링
          this.menu2 = false;
          this.menu2 = true;
        });
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.date)) {
          this.$nextTick(() => {
            this.to = this.date;
          });
        }
        
        this.$nextTick(() => {
          this.date2 = this.to;
          // datepick 다시 랜더링
          this.menu1 = false;
          this.menu1 = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
  },
  created() {
    this.init();
  },
  computed: {
    initHeaders(){
      return {
        SERVICE: 'setting.system.cmmn-code-manage',
        METHOD: "",
        TYPE: 'BIZ_SERVICE',
      };
    },
    paramData(){
      return {
        GROUP_CD: this.param.data.GROUP_CD,
        CD: this.param.data.CD,
        CD_NM: this.param.data.CD_NM,
        CD_USE_FR_DT: this.param.data.CD_USE_FR_DT,
        CD_USE_TO_DT: this.param.data.CD_USE_TO_DT,
        CD_TYPE: this.param.data.CD_TYPE,
        USE_YN: this.param.data.USE_YN,
        SORT_ORD: this.param.data.SORT_ORD,
        ETC_INFO01: this.param.data.ETC_INFO01,
        ETC_INFO02: this.param.data.ETC_INFO02,
        ETC_INFO03: this.param.data.ETC_INFO03,
        ETC_INFO04: this.param.data.ETC_INFO04,
        CD_XPLN: this.param.data.CD_XPLN
      };
    }
  },
};
</script>

<style></style>
