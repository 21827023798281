<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>코드관리</h1>
            <div class="is-right">
            </div>
        </div>

        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="80px">
                    <col width="150px">
                    <col width="90px">
                    <col width="150px">
                    <col width="75px">
                    <col width="120px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>공통코드타입</label></th>
                    <td><v-text-field class="jh-form" name="name" v-model="groupCd" @keyup.enter="search_List"></v-text-field></td>
                    <th><label>공통코드명</label></th>
                    <td><v-text-field class="jh-form" name="name" v-model="cdNm" @keyup.enter="search_List"></v-text-field></td>
                    <th><label>사용여부</label></th>
                    <td>
                        <v-select
                        class="jh-form"
                        :items="dropItems"
                        item-text="name"
                        item-value="value"
                        placeholder="선택하세요"
                        v-model="useYn"
                        ></v-select>
                    </td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="search_List" v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')">조회</v-btn></td>
                </tr>
            </table>
        </div>

        <div class="jh-cols">
            <div class="jh-form-wrap is-col-fix" style="width: 700px;">
                <div class="jh-ui-header">
                    <h2>공통코드현황</h2>
                    <div class="is-right">
                        <v-btn class="jh-btn" v-if="this.mixin_set_btn(this.$options.name, 'btnNew1')" @click="registGroupCd">신규</v-btn>
                        <v-btn class="jh-btn is-light" v-if="this.mixin_set_btn(this.$options.name, 'btnDetail1')" @click="getGroupCdView">상세</v-btn>
                        <v-btn class="jh-btn is-del" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete1')" @click="showDeleteGroupCdConfirm">삭제</v-btn>
                    </div>
                    <v-dialog v-if="dialogE040103P01" max-width="655" persistent v-model="dialogE040103P01">
                        <dialog-E040103P01
                            :param="E040103P01Param"
                            @hide="hideDialog('E040103P01')"
                            @hideAndGetList="hideAndGetList('E040103P01')"
                        >
                        </dialog-E040103P01>
                    </v-dialog>
                </div>
                <!-- <v-data-table
                    class="jh-grid has-control"
                    height="600px"
                    :headers="divGrid01Headers"
                    :items="divGrid01DataText"
                    :items-per-page="divGrid01ItemsPerPage"
                    item-key="id"
                    :item-class="isActiveRow"
                    :page.sync="divGrid01Page"
                    show-select
                    fixed-header
                    hide-default-footer
                    @page-count="divGrid01PageCount = $event"
                    @click:row="getListSel"
                    @dblclick:row="getGroupCdView"
                    v-model="selectedGroupCdList"
                    no-data-text="검색된 결과가 없습니다."
                ></v-data-table>
                <div class="jh-pager">
                    <v-pagination v-model="divGrid01Page" :length="divGrid01PageCount" :total-visible="divGrid01TotalVisible"></v-pagination>
                    <v-select
                      class="jh-form is-round is-right"
                      :items="perPage"
                      style="width: 70px"
                    ></v-select>
                    <span class="jh-counter is-left">총 <em>{{ divGrid01DataText.length }}</em> 건</span>
                </div> -->
                <data-tables
                  ref="dataTable"
                  v-model="selectedGroupCdList"
                  dataTableClass="has-control"
                  :data-table-options="dataTableOptions"
                  @click:row="getListSel"
                  @dblclick:row="getGroupCdView"
                  :paginationOptions="paginationOptions"
                ></data-tables>                
            </div>
            <div class="jh-form-wrap">
                <div class="jh-ui-header">
                    <h2>상세코드현황</h2>
                    <div class="is-right">
                        <v-btn class="jh-btn" v-if="this.mixin_set_btn(this.$options.name, 'btnNew2')" @click="registCd">신규</v-btn>
                        <v-btn class="jh-btn is-light" v-if="this.mixin_set_btn(this.$options.name, 'btnDetail2')" @click="getCdView">상세</v-btn>
                        <v-btn class="jh-btn is-del" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete2')" @click="showDeleteCdConfirm">삭제</v-btn>
                    </div>
                    <v-dialog v-if="dialogE040103P02" max-width="655" persistent v-model="dialogE040103P02">
                        <dialog-E040103P02
                            :param="E040103P02Param"
                            @hide="hideDialog('E040103P02')"
                            @hideAndGetDetailList="hideAndGetDetailList('E040103P02')"
                        >
                        </dialog-E040103P02>
                    </v-dialog>
                </div>
                <!-- <v-data-table
                    class="jh-grid has-control"
                    height="600px"
                    :headers="divGrid02Headers"
                    :items="divGrid02DataText"
                    :items-per-page="divGrid02ItemsPerPage"
                    item-key="id"
                    :item-class="isActiveRow2"
                    :page.sync="divGrid02Page"
                    show-select
                    fixed-header
                    hide-default-footer
                    @page-count="divGrid02PageCount = $event"
                    @click:row="getDetailSel"
                    @dblclick:row="getCdView"
                    v-model="selectedCdList"
                    no-data-text="검색된 결과가 없습니다."
                ></v-data-table>
                <div class="jh-pager">
                    <v-pagination v-model="divGrid02Page" :length="divGrid02PageCount" :total-visible="divGrid02TotalVisible"></v-pagination>
                    <v-select
                      class="jh-form is-round is-right"
                      :items="perPage"
                      style="width: 70px"
                    ></v-select>
                    <span class="jh-counter is-left">총 <em>{{ divGrid02DataText.length }}</em> 건</span>
                </div> -->
                <data-tables
                  ref="dataTable2"
                  v-model="selectedCdList"
                  dataTableClass="has-control"
                  :data-table-options="dataTableOptions2"
                  @click:row="getDetailSel"
                  @dblclick:row="getCdView"
                  :paginationOptions="paginationOptions"
                ></data-tables>  
            </div>
        </div>
    </div>
</template>

<script>
import dataTables from "@/components/DataTables";
import DialogE040103P01 from "./E040103P01.vue";
import DialogE040103P02 from "./E040103P02.vue";
import {mixin} from "../../mixin/mixin";

export default {
  name: "MENU_E040103", //name은 'MENU_' + 파일명 조합
  components: {
    DialogE040103P01,
    DialogE040103P02,
    dataTables
  },
  mixins:[mixin],
  data() {
    return {
      groupCd: '',
      cdNm: '',
      useYn: '',
      url: '',
      selectYn: 'N',

      dialogE040103P01: false,
      dialogE040103P02: false,
      E040103P01Param:{},
      E040103P02Param:{},
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      timeStep: "10:10",
      timeStep2: "05:10",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
      ],
      radioGroup: [
        {
          text: "고객접수시작",
        },
        {
          text: "고객접수중지",
        },
      ],
      radioGroupSelected: "고객접수시작",
      dropItems: [
        { name: "전체", value:"" },
        { name: "사용", value:"Y" },
        { name: "미사용", value:"N" },
      ],
      divGrid01Headers: [
        { text: "No", value: "ROWNUM", align: "center", sortable: true, width: "50px"},
        {
          text: "공통코드타입",
          value: "GROUP_CD",
          align: "center",
          sortable: true,
          width: "100px"
        },
        {
          text: "코드구분",
          value: "CD_TYPE",
          align: "center",
          sortable: true,
          width: "80px"
        },
        {
          text: "공통코드명",
          value: "CD_NM",
          align: "left",
          sortable: true,
        },
        {
          text: "사용여부",
          value: "USE_YN_NM",
          align: "center",
          sortable: true,
          width: "70px"
        },
        {
          text: "정렬순서",
          value: "SORT_ORD",
          align: "center",
          sortable: true,
          width: "70px"
        },
        {
          text: "코드생성자",
          value: "REGR_ID",
          align: "center",
          sortable: true,
          width: "100px"
        },
      ],
      divGrid02Headers: [
        { text: "No", value: "ROWNUM", align: "center", sortable: true, width: "40px" },
        {
          text: "공통코드타입",
          value: "GROUP_CD",
          align: "center",
          sortable: false,
          width: "100px"
        },
        {
          text: "코드구분",
          value: "CD_TYPE",
          align: "center",
          sortable: true,
          width: "80px"
        },
        {
          text: "상세코드",
          value: "CD",
          align: "center",
          sortable: true,
          width: "80px"
        },
        {
          text: "상세코드명",
          value: "CD_NM",
          align: "left",
          sortable: true,
          width: "250px"
        },
        {
          text: "사용여부",
          value: "USE_YN_NM",
          align: "center",
          sortable: true,
          width: "70px"
        },
        {
          text: "정렬순서",
          value: "SORT_ORD",
          align: "center",
          sortable: true,
          width: "70px"
        },
        {
          text: "기타코드1",
          value: "ETC_INFO01",
          align: "center",
          sortable: true,
          width: "120px"
        },
        {
          text: "기타코드2",
          value: "ETC_INFO02",
          align: "center",
          sortable: true,
          width: "120px"
        },
        {
          text: "기타코드3",
          value: "ETC_INFO03",
          align: "center",
          sortable: true,
          width: "120px"
        },
        {
          text: "기타코드4",
          value: "ETC_INFO04",
          align: "center",
          sortable: true,
          width: "120px"
        },
      ],
      divGrid01DataText: [],
      divGrid01Page: 1,
      divGrid01PageCount: 0,
      divGrid01ItemsPerPage: 100,
      divGrid01TotalVisible: 10,

      divGrid02DataText: [],
      divGrid02Page: 1,
      divGrid02PageCount: 0,
      divGrid02ItemsPerPage: 14,
      divGrid02TotalVisible: 10,
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",

      selGroupCd: {},
      selCd: {},
      selectedGroupCdList:[],
      selectedCdList:[],

      commonAlert: false,

      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '600px',
        items: undefined,
        itemKey: 'ROWNUM',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        itemClass: this.isActiveRow,

        load: this.getList
      },
      dataTableOptions2: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '600px',
        items: undefined,
        itemKey: 'ROWNUM',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        itemClass: this.isActiveRow2,

        load: undefined
      },
      paginationOptions: {
          totalVisible: 10
      },

      requestData_List: {},
      requestData_DetailList: {},

    };
  },
  mounted(){
    // 헤더 초기화 및 세팅
    this.setDataTableParams_List();
    // this.getList();
  },
  methods: {
    setDataTableParams_List() {

      this.selGroupCd.GROUP_CD = '';
      this.selCd.CD = '';

      this.requestData_List = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData:{}
      };

      // header 세팅
      this.requestData_List.headers["URL"] = "/api/setting/system/cmmn-code-manage/list";
      this.requestData_List.headers["METHOD"] = "list";
      this.requestData_List.headers["GRID_ID"] = "divGrid01";
      this.requestData_List.headers["ASYNC"] = false;

      // sendData 세팅
      this.requestData_List.sendData["GROUP_CD"] = this.groupCd;
      this.requestData_List.sendData["CD_NM"] = this.cdNm;
      this.requestData_List.sendData["USE_YN"] = this.useYn;
    },
    search_List: function() {
      this.setDataTableParams_List();

      if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
      else this.dataTableInstance.loadData();

      //초기화
      this.dataTableOptions2.load = async function() {
        return {
          data: [],
          totalCount: 0
        }
      };

      this.selectYn = "Y" 

    },
    async getList(loadOptions){
      // 페이징 정보 세팅
      this.requestData_List.headers["ROW_CNT"] = loadOptions.itemsPerPage;
      this.requestData_List.headers["PAGES_CNT"] = loadOptions.page;
      if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
        this.requestData_List.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
      else this.requestData_List.sendData["SORT_ORDR"] = undefined;
      if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
        this.requestData_List.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? 'desc' : 'asc';
      else this.requestData_List.sendData["SORT_DRCT"] = undefined;
      
      return this.common_postCall(this.requestData_List).then(response => {
        return this.getListCallBack(response);
      });
      
    },
    getListCallBack(response){
      if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error');
        return false;
      }
      let header = response.HEADER;
      let data = response.DATA;
      // this.divGrid01DataText = data;
      this.selectedGroupCdList = [];

      return {
        data: data,
        totalCount: header.TOT_COUNT
      };
    },
    getListSel(item, row){
      // 선택한 아이템 저장
      this.selCd.CD = '';
      this.selGroupCd = item;
      this.setDataTableParams_DetailList(item);

      if (!this.dataTableOptions2.load || this.selectYn == "Y") {
        this.selectYn = "N";
        this.dataTableOptions2.load = this.getDetailList;
      }else if (this.dataTableOptions2.page > 1){ 
        this.dataTableOptions2.page = 1;
      }else if(this.selGroupCd.GROUP_CD != this.dataTableOptions2.items[0]?.GROUP_CD) {
        this.dataTable2Instance.loadData();
      }
    },
    getCdView(){
      if( this.selCd.CD == '' || this.mixin_isEmpty(this.selCd) ) {
        this.common_alert(`선택된 항목이 존재하지 않습니다.`, 'error');
        return false;
      }
      // 상세
      this.E040103P02Param = {
        headerTitle: "상세코드 상세",
        data: this.selCd,
        type: "R",
      };
      this.showDialog('E040103P02');
    },
    registGroupCd(){
      // 신규
      this.E040103P01Param = {
        headerTitle: "공통코드 등록",
        data: {},
        type: "C",
      };
      this.showDialog('E040103P01');
    },
    registCd(){
      if( this.mixin_isEmpty(this.selGroupCd) ) {
        this.common_alert(`선택된 항목이 존재하지 않습니다.`, 'error');
        return false;
      }
      // 신규
      this.E040103P02Param = {
        headerTitle: "상세코드 등록",
        data: this.selGroupCd,
        type: "C",
      };
      this.showDialog('E040103P02');
    },
    getGroupCdView(){

      if( this.selGroupCd.GROUP_CD == '' || this.mixin_isEmpty(this.selGroupCd) ) {
        this.common_alert(`선택된 항목이 존재하지 않습니다.`, 'error');
        return false;
      }

      // 상세
      this.E040103P01Param = {
        headerTitle: "공통코드 상세",
        data: this.selGroupCd,
        type: "R",
      };
      this.showDialog('E040103P01');
    },

    setDataTableParams_DetailList: function(item) {
      this.requestData_DetailList = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData:{}
      };

      // header 세팅
      this.requestData_DetailList.headers["URL"] = "/api/setting/system/cmmn-code-manage/detail";
      this.requestData_DetailList.headers["METHOD"] = "detail";
      this.requestData_DetailList.headers["GRID_ID"] = "divGrid02";
      this.requestData_DetailList.headers["ASYNC"] = false;
      this.requestData_DetailList.headers["ROW_CNT"] = 10000;
      this.requestData_DetailList.headers["PAGES_CNT"] = 1;

      // sendData 세팅
      this.requestData_DetailList.sendData["GROUP_CD"] = item.GROUP_CD;
    },
    async getDetailList(loadOptions){
      
      // 페이징 정보 세팅
      this.requestData_DetailList.headers["ROW_CNT"] = loadOptions.itemsPerPage;
      this.requestData_DetailList.headers["PAGES_CNT"] = loadOptions.page;
      if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
        this.requestData_DetailList.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
      else this.requestData_DetailList.sendData["SORT_ORDR"] = 'SORT_ORD';
      if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
        this.requestData_DetailList.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? 'desc' : 'asc';
      else this.requestData_DetailList.sendData["SORT_DRCT"] = 'asc';

      // let requestData = {
      //   headers: {
      //     SERVICE: this.initHeaders.SERVICE,
      //     METHOD: this.initHeaders.METHOD,
      //     TYPE: this.initHeaders.TYPE,
      //   },
      //   sendData:{}
      // };

      // // header 세팅
      // requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/detail";
      // requestData.headers["METHOD"] = "detail";
      // requestData.headers["GRID_ID"] = "divGrid02";
      // requestData.headers["ASYNC"] = false;
      // requestData.headers["ROW_CNT"] = 10000;
      // requestData.headers["PAGES_CNT"] = 1;

      // // sendData 세팅
      // requestData.sendData["GROUP_CD"] = item.GROUP_CD;

      // // 그리드 데이터 초기화
      // this.divGrid02DataText = [];
      // this.selCd = '';

      // // 페이징 초기화 및 세팅
      // this.divGrid02Page= 1;
      // this.divGrid02PageCount= 0;
      // this.divGrid02ItemsPerPage= 14;
      // this.divGrid02TotalVisible= 10;

      return this.common_postCall(this.requestData_DetailList).then(response => {
        return this.getDetailListCallBack(response);
      });
    },
    getDetailListCallBack(response){
      if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error');
        return false;
      }
      let header = response.HEADER;
      let data = response.DATA;

      this.selectedCdList = [];

      if (this.requestData_DetailList.sendData["SORT_ORDR"] == 'SORT_ORD') {
        data.sort((a, b) => {
          return parseInt(a.SORT_ORD) - parseInt(b.SORT_ORD);
        });
      }

      return {
        data: data,
        totalCount: header.TOT_COUNT
      };
    },
    getDetailSel(item){
      this.selCd = item;
    },
    hideAndGetList(type){
      this.hideDialog(type);
      // this.getList();
      // if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
      // else 
        this.dataTableInstance.loadData();
    },
    hideAndGetDetailList(type){
      this.hideDialog(type);
      // this.getDetailList(this.selGroupCd);
      // if (this.dataTableOptions2.page > 1) this.dataTableOptions2.page = 1;
      // else
        this.dataTable2Instance.loadData();
    },
    showDeleteGroupCdConfirm(){
      if( this.mixin_isEmpty(this.selectedGroupCdList) ){
        this.common_alert(`체크된 항목이 존재하지 않습니다.`, 'error');
        return false;
      }
      this.common_confirm(`해당 코드정보를 삭제하시겠습니까?<br>코드타입을 삭제하면 관련 상세코드도 같이 삭제됩니다.`, ()=>{
        this.deleteGroupCd();
      }, null, null, null, "error");
    },
    showDeleteCdConfirm(){// 삭제
      if( this.mixin_isEmpty(this.selectedCdList) ){
        this.common_alert(`체크된 항목이 존재하지 않습니다.`, 'error');
        return false;
      }
      this.common_confirm(`해당 코드정보를 삭제하시겠습니까?`, ()=>{
        this.deleteCd();
      }, null, null, null, "error");
    },
    closeAlert(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    async deleteGroupCd(){
      let deleteList = [];

      for(let i in this.selectedGroupCdList){
        let obj = {
          USE_YN: this.selectedGroupCdList[i].USE_YN,
          CD_TYPE: this.selectedGroupCdList[i].CD_TYPE,
          GROUP_CD: this.selectedGroupCdList[i].GROUP_CD,
          AMDR_DEPT_CD: 'x',
          AMDR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        };
        deleteList.push(obj);
      }

      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {
          list : deleteList
        }
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/delete";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"] = false;
      requestData.headers["FUNCTION_NM"] = "deleteGroupCd";
      let response = await this.common_postCall(requestData);
      this.deleteGroupCdCallBack(response);
    },
    deleteGroupCdCallBack(response){
      if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error');
        return false;
      }
      this.common_alert(`정상처리 되었습니다.`);
      this.selectedGroupCdList = [];
      // this.getList();
      // if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
      // else 
      this.dataTableInstance.loadData();
      this.dataTable2Instance.loadData();
    },
    async deleteCd(){
      let deleteList = [];

      for(let i in this.selectedCdList){
        let obj = {
          USE_YN: this.selectedCdList[i].USE_YN,
          CD_TYPE: this.selectedCdList[i].CD_TYPE,
          GROUP_CD: this.selectedCdList[i].GROUP_CD,
          CD: this.selectedCdList[i].CD,
          AMDR_DEPT_CD: 'x',
          AMDR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        };
        deleteList.push(obj);
      }

      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {
          list : deleteList
        }
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/delete";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"] = false;
      requestData.headers["FUNCTION_NM"] = "deleteCd";
      let response = await this.common_postCall(requestData);
      this.deleteCdCallBack(response);
    },
    deleteCdCallBack(response){
      if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error');
        return false;
      }

      this.common_alert(`정상처리 되었습니다.`);
      this.selectedCdList = [];
      // this.getDetailList(this.selGroupCd);
      // if (this.dataTableOptions2.page > 1) this.dataTableOptions2.page = 1;
      // else
        this.dataTable2Instance.loadData();
    },
    isActiveRow(item){
      return item.GROUP_CD== this.selGroupCd.GROUP_CD? 'is-active':'';
    },
    isActiveRow2(item){
      return item.CD== this.selCd.CD? 'is-active':'';
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      console.log("완료");
      this[`dialog${type}`] = false;
    },
    resetDialog() {
      console.log("reset");
    },
    unlockDialog() {
      console.log("unlock");
    },
  },
  computed: {
    dataTableInstance: function() {
      return this.$refs.dataTable;
    },
    dataTable2Instance: function() {

      return this.$refs.dataTable2;
    },
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    initHeaders(){
      return {
        SERVICE : 'setting.system.cmmn-code-manage',
        METHOD : "",
        TYPE : 'BIZ_SERVICE',
      };
    },
  },
  created() { 
      this.dataTableOptions.headers = this.divGrid01Headers;
      this.dataTableOptions.items = this.divGrid01DataText;
      this.dataTableOptions2.headers = this.divGrid02Headers;
      this.dataTableOptions2.items = this.divGrid02DataText;
  }
};
</script>

<style>

</style>
